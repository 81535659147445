import type { ErrorIdent } from '~/@types/errorIdents';
import { useSecureSessionPost } from '~/composables/dataFetching/genericFetchers';
import { useDialogStore } from '~/stores/useDialogStore';
import { useFavLists } from '~/stores/useFavLists';
import type { Result } from '@/server/api/[site]/user/favList/createFavList.post';
import { handleLoadingError } from '~/utils/handleLoadingError';

const favListStore = useFavLists();

export function useDeleteFavList() {
  const site = useSiteIdent();
  const errors = ref<ErrorIdent[]>([]);
  const dialogStore = useDialogStore();
  const isLoading = ref(false);
  const listId = useRoute().params.id;

  //prevent deletion of favList if it is the default favList
  if (favListStore.myFavoriteList?.id === listId || !listId) {
    dialogStore.closeDialog();
    return;
  }

  async function deleteFavList() {
    try {
      isLoading.value = true;
      const result = await useSecureSessionPost<Result>(
        `/api/${site}/user/favList/deleteFavList`,
        {
          listId: String(listId),
        },
      );

      if (result) {
        await favListStore.loadLists();
        dialogStore.closeDialog();
        navigateToListAfterDelete();
      }
    } catch (e: any) {
      errors.value = e.data.data.errors;
      isLoading.value = false;
      handleLoadingError(e);
    }
  }

  return {
    deleteFavList,
    errors,
    dialogStore,
    isLoading,
  };
}

/**
 * @INFO
 * after delete the user will be navigated to
 *
 * 1. case: the default favList
 * when the list still exists, or the user dosen't have any lists
 *
 * 2. case: the first individual list
 * when the default favList dosen't exist anymore and the user has at least one individual favList
 */
function navigateToListAfterDelete() {
  if (favListStore.myFavoriteList || favListStore.favoriteLists.length == 0) {
    navigateTo('/productlists/favorites');
  } else if (
    !favListStore.myFavoriteList &&
    favListStore.favoriteLists.length > 0
  ) {
    navigateTo(`/productlists/favorites/${favListStore.favoriteLists[0].id}`);
  }
}
